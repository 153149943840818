import React, { Component } from 'react';
import { MDBNavbar, MDBNavbarBrand,MDBBtn,MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse} from "mdbreact";
class Header extends Component {
      constructor(props){
        super(props);
        this.state = {
            isOpen: false,
            custom_class: "hide_sideNavBar",
            collapseID: ""
        };  
      }
      toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
      }
      Change_Slidebar_Custom_Class(event){
        this.props.getSidebarClass({custom_class: "show_sideNavBar"});
      }
    render(){
        return(
            <div className="headerdiv_class">
            {(this.props.setheader_buttons && this.props.setheader_buttons != '' ?
                <MDBNavbar color="mdb-color darken-4" dark expand="md">
                <MDBNavbarBrand className="">
                    {/* <a className="menu_btn_size" rounded size="sm" color=" mdb-color" onClick={this.Change_Slidebar_Custom_Class.bind(this)}><img src="/images/sidebaricon.png" height="35" width="45"/></a> */}
                </MDBNavbarBrand>
                <MDBNavbarBrand>
                    <strong className="white-text"><img src="/images/school_market.png" height="65" width="185"/></strong>
                </MDBNavbarBrand>
                <MDBNavbarToggler onClick={this.toggleCollapse} />
                <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                    <MDBNavbarNav left>
                    <MDBNavItem>
                        <MDBNavLink to={"/home/"+this.props.schoolid+"/"+this.props.userid}><MDBBtn className="menu_btn_size" rounded size="sm" color=" mdb-color">Home</MDBBtn></MDBNavLink>
                    </MDBNavItem>
                    {this.props.setheader_buttons != '' && this.props.setheader_buttons.map((values,index) =>(
                        <div>{index <= 4 ?
                        <MDBNavItem key={index}>
                        <MDBNavLink to={"/productlist/"+values+"/"+this.props.schoolid+"/"+this.props.userid}><MDBBtn className="menu_btn_size" rounded size="sm" color=" mdb-color">{values}</MDBBtn></MDBNavLink>
                        </MDBNavItem>
                        :''}</div>
                    ))}
                    {this.props.setheader_buttons != '' ?
                        <div>
                        <MDBNavItem>
                        <MDBNavLink to={"/productlist/moreproduct/"+this.props.schoolid+"/"+this.props.userid}><MDBBtn className="menu_btn_size" rounded size="sm" color=" mdb-color">More Products</MDBBtn></MDBNavLink>
                        </MDBNavItem>
                        </div> : ''
                    }
                    </MDBNavbarNav>
                </MDBCollapse>
                </MDBNavbar> : ''
            )}
            </div>
        );
    }
}
export default Header;