import React, { Component } from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Home from './home';
import ProductList from './productList';
import About from './about';
class App extends Component {
  render() {
    return (
      <div>
        <Router>
          <Switch>
            <Route exact path="/home/:sid/:userid" component={Home}></Route>        
            <Route exact path="/productlist/:productname/:sid/:userid" component={ProductList}></Route>
            <Route exact path="/about" component={About}></Route>
          </Switch>
        </Router>
      </div>
    );
  }
}

export default App;
