import React, { Component} from 'react';
import { MDBContainer,MDBBtn,MDBCollapse,MDBIcon} from "mdbreact";

class Sidebar extends Component {
    constructor(props){
        super(props);
        this.state = {
            isOpen: false,
            collapseID: ""
          };
    }
    toggleCollapse = () => {
    this.setState({ isOpen: !this.state.isOpen });
    }
    toggleSideBarMenuItemCollapse = collapseID => () => {
    this.setState(prevState => ({
        collapseID: prevState.collapseID !== collapseID ? collapseID : ""
    }));
    }

    Updated_Custom_Class(event){
        this.props.getCustomClass({custom_class: "hide_sideNavBar"});
    }
    render(){
        return(
            <div className="sidebar_class">
                <div className={this.props.setCustomClass}>
                        <div className="row">
                            <MDBContainer className="">
                            <MDBBtn className="menu_btn_size float-right" rounded size="sm" color=" mdb-color" onClick={this.Updated_Custom_Class.bind(this)}>close</MDBBtn>
                            </MDBContainer>
                        </div>
                        <MDBContainer className=""> 
                            {/* <div className="white-text mt-4 mb-4"><img src="./images/school_market.png" height="40" width="150"/></div> */}
                            <div className="row menu-list">
                                    <div className="col-lg-12 transparent p-0"><div className="m-2"><span className="float-left ml-2 mb-2 mt-1"><MDBIcon icon="tachometer-alt mr-3" /> Dashboard</span></div></div>
                                    <div className="col-lg-12 transparent p-0"><div className="m-2"><span className="float-left ml-2 mb-2 mt-1"><MDBIcon icon="tachometer-alt mr-3" />Portfolio</span></div></div>
                                    <div className="col-lg-12 transparent p-0"><div className="m-2"><span className="float-left ml-2 mb-2 mt-1"><MDBIcon icon="tachometer-alt mr-3" /> Help</span></div></div>
                                    <div className="col-lg-12 transparent p-0"><a onClick={this.toggleSideBarMenuItemCollapse("basicCollapse")}><div className="m-2"><span className="float-left ml-2 mb-2 mt-1"><MDBIcon icon="tachometer-alt mr-3" />Our Product</span></div></a></div>
                                    <MDBCollapse id="basicCollapse" className="mdb-color darken-2 row ml-0 mr-0" isOpen={this.state.collapseID}>
                                    <div className="col-lg-12 transparent p-0"><div className="m-2"><span className="float-left ml-2 mb-2 mt-1"><MDBIcon icon="tachometer-alt mr-3" />Portfolio</span></div></div>
                                    <div className="col-lg-12 transparent p-0"><div className="m-2"><span className="float-left ml-2 mb-2 mt-1"><MDBIcon icon="tachometer-alt mr-3" /> Help</span></div></div>
                                    <div className="col-lg-12 transparent p-0"><div className="m-2"><span className="float-left ml-2 mb-2 mt-1"><MDBIcon icon="tachometer-alt mr-3" />Our Product</span></div></div>
                                    </MDBCollapse>
                                    <div className="col-lg-12 transparent p-0"><div className="m-2"><span className="float-left ml-2 mb-2 mt-1"><MDBIcon icon="tachometer-alt mr-3" />About Us</span></div></div>                          
                            </div>
                        </MDBContainer>
                </div>
            </div>
        );
    }
}

export default Sidebar;
