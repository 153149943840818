import React, { Component,Fragment } from 'react';
import { MDBContainer,MDBCard, MDBCardBody,MDBRow,MDBIframe,MDBBtn,MDBNavLink,MDBCol,MDBCardTitle,MDBCardText,MDBCardHeader} from "mdbreact";
class Content extends Component {
    constructor(props){
        super(props);
        this.state = {
            Shooes : [],
            Bags : [],
        }
    }
     render(){
        console.log(this.props.category_type);
        // console.log(this.props.set_product_details[this.props.category_type]);
        return(
            <div className="maindiv_class bg">    
            {(this.props.set_product_details && this.props.set_product_details != "" ?
                    
                    <div>
                        {this.props.category_type == "All" ?
                        <div>
                            <MDBContainer className="content_bg_color">
                            <MDBRow center >
                            {   (this.props.set_product_details != "" && this.props.set_product_details.Bags.length > 0 )? this.props.set_product_details.Bags.map((Bagdata, Bagindex) =>(
                                    <div>{ Bagindex <=4 ? 
                                        <MDBCard key={Bagindex} border="mdb-color lighten-4" className="m-1" style={{ maxWidth: "10rem" }}>
                                        <MDBCardBody className="text-primary">
                                            <MDBIframe className="frameclass" src={Bagdata.trim()}/>
                                        </MDBCardBody>
                                        </MDBCard> : ""}</div>
                                )):''
                            }
                            {   (this.props.set_product_details != "" && this.props.set_product_details.Bags.length > 0 ) ? 
                                    <div>{ 
                                        <MDBCard border="mdb-color lighten-4" className="m-1">
                                        <MDBCardBody className="text-primary moreclass">
                                            <center><MDBNavLink to={"/productlist/Bags/"+this.props.schoolid+"/"+this.props.userid}><MDBBtn className="morebutton_class" rounded size="sm" color=" mdb-color">More Socks</MDBBtn></MDBNavLink></center>
                                        </MDBCardBody>
                                        </MDBCard>}</div> : ''
                            }
                            </MDBRow>
                            </MDBContainer>

                            <MDBContainer className="content_bg_color">
                            <MDBRow center >
                            {   
                                (this.props.set_product_details != "" && this.props.set_product_details.Shoes.length > 0) ? this.props.set_product_details.Shoes.map((Shooes, Shooesindex) =>(
                                    <div>{Shooesindex <=4 ? 
                                        <MDBCard key={Shooesindex} border="mdb-color lighten-4" className="m-1" style={{ maxWidth: "10rem" }}>
                                        <MDBCardBody className="text-primary">
                                            <MDBIframe className="frameclass" src={Shooes.trim()}/>
                                        </MDBCardBody>
                                        </MDBCard> : ""}</div>
                                )):''
                            }
                            {   (this.props.set_product_details != "" && this.props.set_product_details.Shoes.length > 0 ) ? 
                                    <div>{ 
                                        <MDBCard border="mdb-color lighten-4" className="m-1">
                                        <MDBCardBody className="text-primary moreclass">
                                            <center><MDBNavLink to={"/productlist/Shoes/"+this.props.schoolid+"/"+this.props.userid}><MDBBtn className="morebutton_class" rounded size="sm" color=" mdb-color">More Socks</MDBBtn></MDBNavLink></center>
                                        </MDBCardBody>
                                        </MDBCard>}</div> : ''
                            }
                            </MDBRow>
                            </MDBContainer>

                            <MDBContainer className="content_bg_color">
                            <MDBRow center >
                            {   
                                (this.props.set_product_details != "" && this.props.set_product_details.Socks.length > 0) ? this.props.set_product_details.Socks.map((Socks, Socksindex) =>(
                                    <div>{Socksindex <=4 ? 
                                        <MDBCard key={Socksindex} border="mdb-color lighten-4" className="m-1" style={{ maxWidth: "10rem" }}>
                                        <MDBCardBody className="text-primary">
                                            <MDBIframe className="frameclass" src={Socks.trim()}/>
                                        </MDBCardBody>
                                        </MDBCard> : ""}</div>
                                )):''
                            }
                            {   (this.props.set_product_details != "" && this.props.set_product_details.Socks.length > 0 ) ? 
                                    <div>{ 
                                        <MDBCard border="mdb-color lighten-4" className="m-1">
                                        <MDBCardBody className="text-primary moreclass">
                                            <center><MDBNavLink to={"/productlist/Socks/"+this.props.schoolid+"/"+this.props.userid}><MDBBtn className="morebutton_class" rounded size="sm" color=" mdb-color">More Socks</MDBBtn></MDBNavLink></center>
                                        </MDBCardBody>
                                        </MDBCard>}</div> : ''
                            }
                            </MDBRow>
                            </MDBContainer>

                            <MDBContainer className="content_bg_color">
                            <MDBRow center >
                            {   
                                (this.props.set_product_details != "" && this.props.set_product_details.Stationary.length > 0) ? this.props.set_product_details.Stationary.map((Stationary, Stationaryindex) =>(
                                    <div>{Stationaryindex <=4 ? 
                                        <MDBCard key={Stationaryindex} border="mdb-color lighten-4" className="m-1" style={{ maxWidth: "10rem" }}>
                                        <MDBCardBody className="text-primary">
                                            <MDBIframe className="frameclass" src={Stationary.trim()}/>
                                        </MDBCardBody>
                                        </MDBCard> : ""}</div>
                                )):''
                            }
                            {   (this.props.set_product_details != "" && this.props.set_product_details.Stationary.length > 0 ) ? 
                                    <div>{ 
                                        <MDBCard border="mdb-color lighten-4" className="m-1">
                                        <MDBCardBody className="text-primary moreclass">
                                            <center><MDBNavLink to={"/productlist/Stationary/"+this.props.schoolid+"/"+this.props.userid}><MDBBtn className="morebutton_class" rounded size="sm" color=" mdb-color">More Stationary</MDBBtn></MDBNavLink></center>
                                        </MDBCardBody>
                                        </MDBCard>}</div> : ''
                            }
                            </MDBRow>
                            </MDBContainer>
                        </div>
                        :
                        (this.props.set_product_details != "" && this.props.category_type == 'moreproduct' && this.props.more_product_categories != '') ? 
                            <MDBContainer className="content_bg_color">
                            <MDBRow center >
                            {   
                                (this.props.more_product_categories && this.props.more_product_categories != "") ? this.props.more_product_categories.map((values, index) =>(
                                    <MDBCard border="mdb-color lighten-4 " className="m-1 cardbackgroundclass">
                                    <MDBCardBody className="text-primary moreclass">
                                        <MDBNavLink to={"/productlist/"+values+"/"+this.props.schoolid+"/"+this.props.userid}><MDBBtn className="morebutton_class morebutton_class_new" rounded size="sm" color="lime darken-1"><b>{values}</b></MDBBtn></MDBNavLink>
                                    </MDBCardBody>
                                    </MDBCard> 
                                )):''
                            }
                            </MDBRow>
                            </MDBContainer>   
                        :(this.props.set_product_details && this.props.set_product_details[this.props.category_type] == undefined) ?
                            <MDBContainer className="mt-5">
                            <MDBCard>
                              <MDBCardBody>Products are not available for this categories.</MDBCardBody>
                            </MDBCard>
                          </MDBContainer>
                        :(this.props.set_product_details != "" && this.props.set_product_details[this.props.category_type].length > 0) ?
                        
                            <MDBContainer className="content_bg_color">
                            <MDBRow center >
                            {(this.props.set_product_details != "" && this.props.set_product_details[this.props.category_type].length > 0) ? this.props.set_product_details[this.props.category_type].map((values, index) =>(
                                    <div>
                                        <MDBCard key={index} border="mdb-color lighten-4" className="m-1" style={{ maxWidth: "10rem" }}>
                                        <MDBCardBody className="text-primary">
                                            <MDBIframe className="frameclass" src={values.trim()}/>
                                        </MDBCardBody>
                                        </MDBCard> 
                                    </div>
                                )):<div className="d-flex justify-content-center"><div className="spinner-border text-primary loader_class_on_main_page " role="status"></div></div>
                            }
                            </MDBRow>
                            </MDBContainer>
                        
                        :
                        <div>
                            No Data Available
                        </div>
                    }
                    </div>

                : <div className="d-flex justify-content-center"><div className="spinner-border text-light loader_class_on_main_page " role="status"></div></div>
                )

            }
            </div>       
        );
    }
}
export default Content;