import React, { Component } from 'react';
import { MDBContainer,MDBFooter} from "mdbreact";
class Footer extends Component {
    render(){
        return(
            <div className="footerdiv_class bg_footer">
                <MDBFooter color="mdb-color darken-4" className="font-small pt-4">
                    <MDBContainer fluid>&copy; {new Date().getFullYear()} Copyright: <a href="https://www.prisms.in"> prisms.in </a></MDBContainer>
                </MDBFooter>
            </div>
        );
    }
}
export default Footer;