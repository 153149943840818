import React , { Component } from 'react';
import "@fortawesome/fontawesome-free/css/all.min.css";
import 'bootstrap-css-only/css/bootstrap.min.css'; 
import 'mdbreact/dist/css/mdb.css';
// Be sure to include styles at some point, probably during your bootstraping
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import './App.css';
import Header from './Header/header';
import Content from './Content/content';
import Sidebar from './Sidebar/sidebar';
import Footer from './Footer/footer';
import axios from 'axios';

class Home extends Component {
    constructor(props){
        super(props);
        this.state = {
            custom_class:"hide_sideNavBar",
            product_categories:[],
            product_details:[],
            type : '',
            sid : this.props.match.params.sid?this.props.match.params.sid : '',
            userid : this.props.match.params.userid?this.props.match.params.userid : ''
        };
    }  
    Change_Sidebar_Class(value){
        this.setState({
        custom_class : value.custom_class
        });
        // console.log(value.custom_class);
    }
    get_Product_Details(type,self)
    {
        axios.post('http://topschool.prisms.in/rest/index.php/user_list.json?', {['fun_name']:"get_product_details",['sid']:this.state.sid,['userid']:this.state.userid,['type']:type})
        .then(function(result){
        // console.log(result.data.Product);
            self.setState({
                product_categories : result.data.category,
                product_details : result.data.Product,
                type : type
            });
        })
    }
    componentDidMount(){
        var type = "All"
        this.get_Product_Details(type,this);
    }
    render() {
        console.log(this.state.product_categories);
        return (
                <div className="App row">
                    <Header getSidebarClass={this.Change_Sidebar_Class.bind(this)} setheader_buttons={this.state.product_categories} schoolid={this.state.sid} userid={this.state.userid}/>
                    <Sidebar setCustomClass={this.state.custom_class} getCustomClass={this.Change_Sidebar_Class.bind(this)}></Sidebar>
                    <Content 
                        set_product_details={this.state.product_details} 
                        category_type = {this.state.type}
                        schoolid={this.state.sid} 
                        userid={this.state.userid}
                    ></Content>
                    <Footer></Footer>
                </div>
            );
    }
}

export default Home;