import React , { Component } from 'react';

class About extends Component{
    render(){
        return(
            <div>
                About US
            </div>
        );
    }
}

export default About;